
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexi_450ZDPltJBxtaehw7kv8OxEm_VUu_qc5QymmYJ4Gg6IMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/index.vue?macro=true";
import { default as indexIseHl1D6IoADSCVYYvjND3tGFE_bwk0EPc5EGQW1hScMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startngPlYphhiPz0zTkE0w8dLIEx0KZCsokKdWwK0MMdIEIMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as starte1eVxhDwPhinvnxpz_v5UvdPaUfkara69ZZXG5pWTFUMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutiLvwyRDSUj0t_TBo_45vqCdUjukuwHEGRiTF40TvLJRmEMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koCrZqy0XQMusCLFPI5bqZbZbexvJQBBFslTlM_459Cv91UMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationvbe1fb8VEDjzUjEw5YQG_45d2c8dmrrZnqhdh_M2tSNiMMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmation0wfmKHN3rUZPuUUwwyVMiJp83td5CsNmPz1DL5tGGUoMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyer2OjE3er4IrPpgt3qB0NA5n5RizGJ7wI1JhODpuHWLPkMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyer5JMsa8LvdADQoF9u31PvKwUUWu4pZXLbnzSjOuV5JwwMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentYUNa7TgbjtIAWjGcTd2GSuxD_AZX7hLliUlwrtEMLMEMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengeXsLhISdQ_2m7Op_45_E8h12Ay6zZqIj2TiFZxdlR8n2AIMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentzVVuPjaDjwi9M2NpWnAoakHy0GX_Ix83kFHXQKqRSW8Meta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta } from "/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexi_450ZDPltJBxtaehw7kv8OxEm_VUu_qc5QymmYJ4Gg6IMeta?.name ?? "index",
    path: indexi_450ZDPltJBxtaehw7kv8OxEm_VUu_qc5QymmYJ4Gg6IMeta?.path ?? "/",
    props: indexi_450ZDPltJBxtaehw7kv8OxEm_VUu_qc5QymmYJ4Gg6IMeta?.props ?? false,
    meta: indexi_450ZDPltJBxtaehw7kv8OxEm_VUu_qc5QymmYJ4Gg6IMeta || {},
    alias: indexi_450ZDPltJBxtaehw7kv8OxEm_VUu_qc5QymmYJ4Gg6IMeta?.alias || [],
    redirect: indexi_450ZDPltJBxtaehw7kv8OxEm_VUu_qc5QymmYJ4Gg6IMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/index.vue")
  },
  {
    name: indexIseHl1D6IoADSCVYYvjND3tGFE_bwk0EPc5EGQW1hScMeta?.name ?? "lang",
    path: indexIseHl1D6IoADSCVYYvjND3tGFE_bwk0EPc5EGQW1hScMeta?.path ?? "/:lang?",
    props: indexIseHl1D6IoADSCVYYvjND3tGFE_bwk0EPc5EGQW1hScMeta?.props ?? false,
    meta: indexIseHl1D6IoADSCVYYvjND3tGFE_bwk0EPc5EGQW1hScMeta || {},
    alias: indexIseHl1D6IoADSCVYYvjND3tGFE_bwk0EPc5EGQW1hScMeta?.alias || [],
    redirect: indexIseHl1D6IoADSCVYYvjND3tGFE_bwk0EPc5EGQW1hScMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startngPlYphhiPz0zTkE0w8dLIEx0KZCsokKdWwK0MMdIEIMeta?.name ?? "lang-voucher-start",
    path: startngPlYphhiPz0zTkE0w8dLIEx0KZCsokKdWwK0MMdIEIMeta?.path ?? "/:lang?/voucher/start",
    props: startngPlYphhiPz0zTkE0w8dLIEx0KZCsokKdWwK0MMdIEIMeta?.props ?? false,
    meta: startngPlYphhiPz0zTkE0w8dLIEx0KZCsokKdWwK0MMdIEIMeta || {},
    alias: startngPlYphhiPz0zTkE0w8dLIEx0KZCsokKdWwK0MMdIEIMeta?.alias || [],
    redirect: startngPlYphhiPz0zTkE0w8dLIEx0KZCsokKdWwK0MMdIEIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: starte1eVxhDwPhinvnxpz_v5UvdPaUfkara69ZZXG5pWTFUMeta?.name ?? "lang-purchase-start",
    path: starte1eVxhDwPhinvnxpz_v5UvdPaUfkara69ZZXG5pWTFUMeta?.path ?? "/:lang?/purchase/start",
    props: starte1eVxhDwPhinvnxpz_v5UvdPaUfkara69ZZXG5pWTFUMeta?.props ?? false,
    meta: starte1eVxhDwPhinvnxpz_v5UvdPaUfkara69ZZXG5pWTFUMeta || {},
    alias: starte1eVxhDwPhinvnxpz_v5UvdPaUfkara69ZZXG5pWTFUMeta?.alias || [],
    redirect: starte1eVxhDwPhinvnxpz_v5UvdPaUfkara69ZZXG5pWTFUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutiLvwyRDSUj0t_TBo_45vqCdUjukuwHEGRiTF40TvLJRmEMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutiLvwyRDSUj0t_TBo_45vqCdUjukuwHEGRiTF40TvLJRmEMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutiLvwyRDSUj0t_TBo_45vqCdUjukuwHEGRiTF40TvLJRmEMeta?.props ?? false,
    meta: checkoutiLvwyRDSUj0t_TBo_45vqCdUjukuwHEGRiTF40TvLJRmEMeta || {},
    alias: checkoutiLvwyRDSUj0t_TBo_45vqCdUjukuwHEGRiTF40TvLJRmEMeta?.alias || [],
    redirect: checkoutiLvwyRDSUj0t_TBo_45vqCdUjukuwHEGRiTF40TvLJRmEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koCrZqy0XQMusCLFPI5bqZbZbexvJQBBFslTlM_459Cv91UMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koCrZqy0XQMusCLFPI5bqZbZbexvJQBBFslTlM_459Cv91UMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koCrZqy0XQMusCLFPI5bqZbZbexvJQBBFslTlM_459Cv91UMeta?.props ?? false,
    meta: koCrZqy0XQMusCLFPI5bqZbZbexvJQBBFslTlM_459Cv91UMeta || {},
    alias: koCrZqy0XQMusCLFPI5bqZbZbexvJQBBFslTlM_459Cv91UMeta?.alias || [],
    redirect: koCrZqy0XQMusCLFPI5bqZbZbexvJQBBFslTlM_459Cv91UMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationvbe1fb8VEDjzUjEw5YQG_45d2c8dmrrZnqhdh_M2tSNiMMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationvbe1fb8VEDjzUjEw5YQG_45d2c8dmrrZnqhdh_M2tSNiMMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationvbe1fb8VEDjzUjEw5YQG_45d2c8dmrrZnqhdh_M2tSNiMMeta?.props ?? false,
    meta: confirmationvbe1fb8VEDjzUjEw5YQG_45d2c8dmrrZnqhdh_M2tSNiMMeta || {},
    alias: confirmationvbe1fb8VEDjzUjEw5YQG_45d2c8dmrrZnqhdh_M2tSNiMMeta?.alias || [],
    redirect: confirmationvbe1fb8VEDjzUjEw5YQG_45d2c8dmrrZnqhdh_M2tSNiMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmation0wfmKHN3rUZPuUUwwyVMiJp83td5CsNmPz1DL5tGGUoMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmation0wfmKHN3rUZPuUUwwyVMiJp83td5CsNmPz1DL5tGGUoMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmation0wfmKHN3rUZPuUUwwyVMiJp83td5CsNmPz1DL5tGGUoMeta?.props ?? false,
    meta: confirmation0wfmKHN3rUZPuUUwwyVMiJp83td5CsNmPz1DL5tGGUoMeta || {},
    alias: confirmation0wfmKHN3rUZPuUUwwyVMiJp83td5CsNmPz1DL5tGGUoMeta?.alias || [],
    redirect: confirmation0wfmKHN3rUZPuUUwwyVMiJp83td5CsNmPz1DL5tGGUoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyer2OjE3er4IrPpgt3qB0NA5n5RizGJ7wI1JhODpuHWLPkMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyer2OjE3er4IrPpgt3qB0NA5n5RizGJ7wI1JhODpuHWLPkMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyer2OjE3er4IrPpgt3qB0NA5n5RizGJ7wI1JhODpuHWLPkMeta?.props ?? false,
    meta: buyer2OjE3er4IrPpgt3qB0NA5n5RizGJ7wI1JhODpuHWLPkMeta || {},
    alias: buyer2OjE3er4IrPpgt3qB0NA5n5RizGJ7wI1JhODpuHWLPkMeta?.alias || [],
    redirect: buyer2OjE3er4IrPpgt3qB0NA5n5RizGJ7wI1JhODpuHWLPkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyer5JMsa8LvdADQoF9u31PvKwUUWu4pZXLbnzSjOuV5JwwMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyer5JMsa8LvdADQoF9u31PvKwUUWu4pZXLbnzSjOuV5JwwMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyer5JMsa8LvdADQoF9u31PvKwUUWu4pZXLbnzSjOuV5JwwMeta?.props ?? false,
    meta: buyer5JMsa8LvdADQoF9u31PvKwUUWu4pZXLbnzSjOuV5JwwMeta || {},
    alias: buyer5JMsa8LvdADQoF9u31PvKwUUWu4pZXLbnzSjOuV5JwwMeta?.alias || [],
    redirect: buyer5JMsa8LvdADQoF9u31PvKwUUWu4pZXLbnzSjOuV5JwwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentYUNa7TgbjtIAWjGcTd2GSuxD_AZX7hLliUlwrtEMLMEMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentYUNa7TgbjtIAWjGcTd2GSuxD_AZX7hLliUlwrtEMLMEMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentYUNa7TgbjtIAWjGcTd2GSuxD_AZX7hLliUlwrtEMLMEMeta?.props ?? false,
    meta: paymentYUNa7TgbjtIAWjGcTd2GSuxD_AZX7hLliUlwrtEMLMEMeta || {},
    alias: paymentYUNa7TgbjtIAWjGcTd2GSuxD_AZX7hLliUlwrtEMLMEMeta?.alias || [],
    redirect: paymentYUNa7TgbjtIAWjGcTd2GSuxD_AZX7hLliUlwrtEMLMEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengeXsLhISdQ_2m7Op_45_E8h12Ay6zZqIj2TiFZxdlR8n2AIMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengeXsLhISdQ_2m7Op_45_E8h12Ay6zZqIj2TiFZxdlR8n2AIMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengeXsLhISdQ_2m7Op_45_E8h12Ay6zZqIj2TiFZxdlR8n2AIMeta?.props ?? false,
    meta: challengeXsLhISdQ_2m7Op_45_E8h12Ay6zZqIj2TiFZxdlR8n2AIMeta || {},
    alias: challengeXsLhISdQ_2m7Op_45_E8h12Ay6zZqIj2TiFZxdlR8n2AIMeta?.alias || [],
    redirect: challengeXsLhISdQ_2m7Op_45_E8h12Ay6zZqIj2TiFZxdlR8n2AIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentzVVuPjaDjwi9M2NpWnAoakHy0GX_Ix83kFHXQKqRSW8Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentzVVuPjaDjwi9M2NpWnAoakHy0GX_Ix83kFHXQKqRSW8Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentzVVuPjaDjwi9M2NpWnAoakHy0GX_Ix83kFHXQKqRSW8Meta?.props ?? false,
    meta: paymentzVVuPjaDjwi9M2NpWnAoakHy0GX_Ix83kFHXQKqRSW8Meta || {},
    alias: paymentzVVuPjaDjwi9M2NpWnAoakHy0GX_Ix83kFHXQKqRSW8Meta?.alias || [],
    redirect: paymentzVVuPjaDjwi9M2NpWnAoakHy0GX_Ix83kFHXQKqRSW8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/cala-millor/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/calvia/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/colonia-sant-jordi/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/felanitx/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/llucmajor/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/montuiri/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/muro/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/palma/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/pollenca/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/sant-llorenc-des-cardassar/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/soller/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.path ?? "/spa-balneario/mallorca/son-servera/",
    props: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.props ?? false,
    meta: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta || {},
    alias: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.alias || [],
    redirect: SpaList_46pagef4rm7rQrg_0FckU4EvOOBwSohvGA8r7BrWuyxseWEmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/coronado-thalasso-spa/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 16",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 17",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 18",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 19",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 20",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 21",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 22",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.name ?? "Spa landing: 23",
    path: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/",
    props: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.props ?? false,
    meta: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta || {},
    alias: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.alias || [],
    redirect: SpaDetail_46pageWHNxWUSMbahSmaN7kHU_45j92WosPfYqjKEf_45UypT036gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/circuito-spa-coolifting/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/facial-antiage-con-nanotecnologia/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/algoterapia/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/ritual-ca-nostra/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/ritual-primavera-aromas-de-la-pradera/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/acupuntura/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/fallin-relax/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/peeling-personalizado/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/brunch-circuito-de-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/circuito-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/lodoterapia-espalda/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/peeling-masaje/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/bye-dolor/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/ritual-chocolate-y-rosas-nutri/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/masaje-relajante/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/masaje-antiestres-circuito-de-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/desayuno-buffet-circuito-de-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/masaje-espalda-circuito-de-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/almuerzo-circuito-de-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/day-pass-de-tarde-spa-cena-buffet/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/day-pass-con-desayuno-buffet-y-acceso-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/sky-bar-cena-en-el-restaurante-tamashi-spa-cocktail/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/day-pass-con-cama-balinesa-almuerzo-a-la-carta-para-2-personas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/day-pass-con-brunch-y-acceso-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-masaje-25-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-masaje-50-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-desayuno-buffet/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-masaje-aromatico/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/daypass-circuito-spa-masaje-de-25-menu-migdia-en-restaurante-aromata-de-andreu-genestra/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/daypass-circuito-spa-masaje-de-25-menu-degustacion-aromata-en-restaurante-aromata-de-andreu-genestra/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/daypass-circuito-spa-menu-migdia-en-restaurante-aromata-de-andreu-genestra/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/daypass-circuito-spa-menu-degustacion-aromata-en-restaurante-aromata-de-andreu-genestra/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-50-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-holistico-jacuzzi-privado-para-2-personas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-sensorial/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-30-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-30-min-para-2-personas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-50-min-para-2-personas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-30-min-desayuno-buffet/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-holistico-desayuno-buffet-para-2-personas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-holistico-cena-de-gala-para-2-personas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-tratamiento-de-andulacion/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/pack-have-lunch/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/zona-de-aguas-masaje-50-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/pack-relax-matutino/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/zona-de-aguas-masaje-25-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/zona-de-aguas-masaje-sensorial/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/pack-romantico/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/coronado-thalasso-spa/spa-desayuno/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/coronado-thalasso-spa/day-pass-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/coronado-thalasso-spa/spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/masaje-lomi-lomi-nui-circuito-de-aguas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/gastrospa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/circuito-de-aguas-tratamiento-facial-detox-botox-like-spiruline-boost/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/masaje-antiestres-aromatico-circuito-de-aguas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/degustacion-de-vinos-y-quesos-circuito-de-aguas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/beauty-party/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/mananas-deluxe/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/senses-night/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/especial-cumpleanos/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/circuito-de-aguas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/masaje-antiestres/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/bahia-signature-ritual-especial-parejas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/facial-express-marine-anti-fatigue/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/masaje-deep-tissue/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/hidraforce-source-marine/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/circuito-termal-individual-1/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/especial-residentes-spa-masaje/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-con-pindas-y-esferas-calientes-de-sal-assal-40-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/ritual-especial-dia-de-la-madre/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-celebracion-de-tu-cumpleanos/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/ruta-de-las-delicias/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-con-aceite-de-cristal-by-silentia-50-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-balines-50-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/acceso-silentia-spa-no-residentes/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/acceso-silentia-spa-residentes/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/escapada-placer-de-vivir-sin-prisa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/magia-de-la-luna/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-pre-mama-40-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-celebracion-de-tu-aniversario/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-energizante-ayurvedico-de-la-india-60-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-con-aceite-de-cristal-by-silentia-25-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-equilibrio-natural-con-piedras-calientes-y-frias-de-jade-40-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/banos/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/amigas-para-siempre/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-espalda-aromaterapia/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/instante-romantico/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/energy-time/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/collagen-booster/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/aqua-rituals/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/abhyanga-viaje-sensorial/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/ritual-mallorca-wellness/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/ritual-ayurvedico-armonia-total/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-relax-espalda/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-ritual-en-pareja/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-cuerpo-entero-y-craneofacial/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/ritual-piedras-calientes/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/deep-tissue/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-cuerpo-entero/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/back-detox/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/serenity/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/hidraforce-source-marine/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/for-men-ocean-intense/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/antiestres/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/circuito-termal-doble/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/desayuno-circuito-termal/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/circuito-termal-individual/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/circuito-hydrowellness/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/brunch-circuito-termal/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/deep-tissue-40-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/stone-therapy-1/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/especifico-localizado/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/after-work-masaje-25min-circuito-termal/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/masaje-aromatico-45min-circuito-termal/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/masaje-lomi-lomi-nui-55min-circuito-termal/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/diamond-well-living-by-natura-bisse-90/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/ritual-mediterranean-by-natura-bisse/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/ritual-de-piedras-de-sal-by-comfort-zone/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/the-cure-therapy-by-natura-bisse/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/ritual-finca-serena-by-finca-serena/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/masaje-bespoke-60/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/diamond-well-living-by-natura-bisse-60/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/podal-reflexology/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/masaje-muscular-60/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/diamond-experience-facial-ritual-by-natura-bisse/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/masaje-relajante-60/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/citrus-vita-essence-by-natura-bisse/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/shaping-massage-by-comfort-zone/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/sublime-skin-by-comfort-zone/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/remedy-by-comfort-zone/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/acceso-spa-masaje-relajante/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/clase-de-yoga-desayuno-acceso-spa-masaje-relajante/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/clase-de-yoga-acceso-spa-masaje-relajante/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/acceso-spa-desayuno-masaje-relajante/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/daypass-con-almuerzo-en-es-clot-pool-bar-sin-acceso-a-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/daypass-con-cocktail-y-aperitivo-sin-acceso-a-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/daypass-con-cocktail-y-aperitivo-acceso-a-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/acceso-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/acceso-spa-cena-menu-degustacion/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/acceso-spa-desayuno/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/daypass-con-almuerzo-en-es-clot-pool-bar-acceso-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/daypass-con-desayuno-acceso-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/masaje-y-spa-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/jacuzzi-privado-spa-y-masaje-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/experiencia-best-friends-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/pro-radiance-facial-y-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/spa-masaje-y-almuerzo-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/full-day-spa-y-masaje/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/jacuzzi-privado-spa-masaje-y-almuerzo-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/ritual-ayurvedico-y-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/active-recovery-y-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/masaje-deluxe-y-spa-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/circuito-spa-brunch-domingos/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/circuito-spa-almuerzo-menu/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/circuito-spa-desayuno-buffet/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/circuito-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/masaje-sueco-30-min-doble/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/masaje-sueco-60-min-doble/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/masaje-sueco-30-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/masaje-sueco-60-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/day-pass-dia-completo/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/day-pass-5h-lunes-a-jueves/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/aromaterapia-con-velas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/aromaterapia-con-velas-doble/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/holistic-experience-60-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/pure-salt-ritual/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/o-spa-signature-doble/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/o-spa-signature/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/cielo-y-tierra-30-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-cena/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/masaje-aromaterapia-50min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/bienestar-para-dos-50min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/ritual-le-voyage-1h-15min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-almuerzo/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-masaje-gastronomia/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/masaje-relajante-50min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-desayuno/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-masaje-desayuno/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-oasis/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/las-mil-y-una-noche-masaje-35min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/masaje-desayuno/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/ritual-namaste-en-pareja-1h/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-ninos/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/masaje-de-espalda-cuello-hombros-25min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/masaje-prenatal-50min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/relajacion-de-son-claret-aromaterapia-60-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/ritual-corporal-de-temporada/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/facial-bellesa-de-claret/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/flor-de-almendro-oferta-para-residentes/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/relajacion-tejido-profundo-jardin-de-son-claret-exterior-60-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/relajacion-jardin-de-son-claret-exterior-90-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/bellesas-anti-estres-60-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/spa-con-masaje-y-almuerzo/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/day-pass-con-cama-balinesa-y-gastronomia-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/la-sulatane-de-saba-facial-90min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/hammam/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/masaje-con-pindas-herbales-calientes-60-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/masaje-con-piedras-calientes-y-piedras-semi-preciosas-60-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/relajacion-tejido-profundo-60-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-holistico-desayuno-buffet-para-2-personas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-30-min-para-2-personas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-50-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-30-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-30-min-desayuno-buffet/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-holistico-cena-para-2-personas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-sensorial/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/drenaje-linfatico-metodo-vodderr/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/mens-facial/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/momento-spa-masaje/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-terapeutico/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-beauty-back/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/tonic-c/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/mediterraneo/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/ayurveda-pinda-swedana/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/soller-sensaciones/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-antiestres/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-piedras-calientes/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-mediterranean-champi/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/ayurveda-abhyanga/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/jacuzzi-privado-spa-masaje-y-almuerzo-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/jacuzzi-privado-spa-y-masaje-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/experiencia-best-friends-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/pro-radiance-facial-y-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/full-day-spa-y-masaje/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/active-recovery-y-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-deluxe-y-spa-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 228",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/spa-masaje-y-almuerzo-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 229",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/ritual-ayurvedico-y-spa/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 230",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-y-spa-para-2/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 231",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/noches-privadas-spa-1hora-masaje-30-para-dos/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 232",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-taoista-real/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 233",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/tratamiento-taoista/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 234",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/gran-ritual-de-polinesia-aloha/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 235",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/gran-ritual-de-magreb-90-minutos/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 236",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/celestial-day/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 237",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-iluminador-flores-de-bali-50/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 238",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/mallorca-relajese-y-disfrute/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 239",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/relajese-disfrute-de-verano/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 240",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-balines-a-4-manos/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 241",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-dulce-espera/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 242",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-relajante-oriental-80/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 243",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/acceso-spa-no-residente/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 244",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/acceso-spa-residente/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 245",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/felicidad-a-duo/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 246",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/relajese-disfrute-premium/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 247",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-euforia-doble/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 248",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/noches-privadas-spa-1hs/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 249",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/cozzy-uso-privado-por-5-horas/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 250",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-energizante-ayurveda-con-welcome-touch-para-dos-50-min/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 251",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/mar-y-cielo/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 252",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/day-pass-masaje-60/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 253",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/masaje-relajante/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 254",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/day-pass-almuerzo/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 255",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/day-pass/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.name ?? "Service landing: 256",
    path: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/masaje-deportivo/",
    props: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.props ?? false,
    meta: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta || {},
    alias: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.alias || [],
    redirect: ServiceDetail_46pagewGIr0Rh9yzrT_Ee0OYHzWh3O9E7RBLggIwU0fZA8etwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-1790f443-cc1b-44c2-9698-2245da37f1cd/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]